import { default as activateVWZf9wNMQYMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/activate.vue?macro=true";
import { default as indexoSJc5P9PkaMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/index.vue?macro=true";
import { default as restorewCXHlhWc9gMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/organisations/restore.vue?macro=true";
import { default as register_45completeLoWR82vJYOMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/register-complete.vue?macro=true";
import { default as registerIzBKsCdiiHMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/register.vue?macro=true";
import { default as _91token_93vqC5mzqV1IMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/boards/[token].vue?macro=true";
import { default as indexjppejCsG31Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93NMV0tHXihSMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/dashboards/[id].vue?macro=true";
import { default as indexfLdCIEBIe1Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93Ww5H51PjGvMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/dashboards/token/[token].vue?macro=true";
import { default as token6Uo5LGhhpFMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/dashboards/token.vue?macro=true";
import { default as demo4QlW7BWGr1Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/demo.vue?macro=true";
import { default as index7MTclSwfL4Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/events/index.vue?macro=true";
import { default as impressumrgbOBORvYCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/impressum.vue?macro=true";
import { default as indexHRyZqqYla1Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/index.vue?macro=true";
import { default as _91id_9375msu31TUPMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/leaderboards/[id].vue?macro=true";
import { default as indexHHqhWx6ixJMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/leaderboards/index.vue?macro=true";
import { default as _91id_93nAva7e2z2ZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexbGOPnEOUTSMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/deals/index.vue?macro=true";
import { default as HSLtoRGBYBPctswtnLMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as RGBtoHSLpDezQHWxOVMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93ul2KYIAnKdMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93C34V1Nryp2Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as indexkLPcHU4rQ5Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/index.vue?macro=true";
import { default as _91id_93YlkS3vQ5laMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as indexDjIJhmU1FEMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_93sIfprInJolMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexUoVxr0yd1gMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/goals/index.vue?macro=true";
import { default as indexlm8DF85VHoMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/settings/index.vue?macro=true";
import { default as indexSk37fzr5ZZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_93CNPbRzViwLMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexhw8h1kOtwlMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93CT9nzTUlybMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/sounds/[id].vue?macro=true";
import { default as entriesmYDkYVWDedMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlistsvlrl99CxM4Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexDl7rNGo3EoMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/sounds/index.vue?macro=true";
import { default as index2ERSgf1stXMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_93O9qkTGf7W2Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexahvI4Llv3lMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93qR86WL4H6AMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexNFTkoYWVWOMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/hardware/index.vue?macro=true";
import { default as indexYV4aDdY65FMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_93cJurpqkc94Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexW8F1E8YhVtMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_937NFTIDUcHCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexZCKQ6obLSHMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93HscRRxYGSYMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/plans/[id].vue?macro=true";
import { default as indexYiAXQIkhsCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/plans/index.vue?macro=true";
import { default as releaselogFDjt4vSODHMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/releaselog.vue?macro=true";
import { default as toolsMJirvS4ZRCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/tools.vue?macro=true";
import { default as _91id_93Lxl2OKAStYMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/users/[id].vue?macro=true";
import { default as indexAJ8MW9m8o8Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: activateVWZf9wNMQYMeta?.name ?? "auth-activate",
    path: activateVWZf9wNMQYMeta?.path ?? "/auth/activate",
    meta: activateVWZf9wNMQYMeta || {},
    alias: activateVWZf9wNMQYMeta?.alias || [],
    redirect: activateVWZf9wNMQYMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: indexoSJc5P9PkaMeta?.name ?? "auth",
    path: indexoSJc5P9PkaMeta?.path ?? "/auth",
    meta: indexoSJc5P9PkaMeta || {},
    alias: indexoSJc5P9PkaMeta?.alias || [],
    redirect: indexoSJc5P9PkaMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: restorewCXHlhWc9gMeta?.name ?? "auth-organisations-restore",
    path: restorewCXHlhWc9gMeta?.path ?? "/auth/organisations/restore",
    meta: restorewCXHlhWc9gMeta || {},
    alias: restorewCXHlhWc9gMeta?.alias || [],
    redirect: restorewCXHlhWc9gMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/organisations/restore.vue").then(m => m.default || m)
  },
  {
    name: register_45completeLoWR82vJYOMeta?.name ?? "auth-register-complete",
    path: register_45completeLoWR82vJYOMeta?.path ?? "/auth/register-complete",
    meta: register_45completeLoWR82vJYOMeta || {},
    alias: register_45completeLoWR82vJYOMeta?.alias || [],
    redirect: register_45completeLoWR82vJYOMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/register-complete.vue").then(m => m.default || m)
  },
  {
    name: registerIzBKsCdiiHMeta?.name ?? "auth-register",
    path: registerIzBKsCdiiHMeta?.path ?? "/auth/register",
    meta: registerIzBKsCdiiHMeta || {},
    alias: registerIzBKsCdiiHMeta?.alias || [],
    redirect: registerIzBKsCdiiHMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91token_93vqC5mzqV1IMeta?.name ?? "boards-token",
    path: _91token_93vqC5mzqV1IMeta?.path ?? "/boards/:token()",
    meta: _91token_93vqC5mzqV1IMeta || {},
    alias: _91token_93vqC5mzqV1IMeta?.alias || [],
    redirect: _91token_93vqC5mzqV1IMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/boards/[token].vue").then(m => m.default || m)
  },
  {
    name: indexjppejCsG31Meta?.name ?? "buzzer",
    path: indexjppejCsG31Meta?.path ?? "/buzzer",
    meta: indexjppejCsG31Meta || {},
    alias: indexjppejCsG31Meta?.alias || [],
    redirect: indexjppejCsG31Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/buzzer/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NMV0tHXihSMeta?.name ?? "dashboards-id",
    path: _91id_93NMV0tHXihSMeta?.path ?? "/dashboards/:id()",
    meta: _91id_93NMV0tHXihSMeta || {},
    alias: _91id_93NMV0tHXihSMeta?.alias || [],
    redirect: _91id_93NMV0tHXihSMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/dashboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfLdCIEBIe1Meta?.name ?? "dashboards",
    path: indexfLdCIEBIe1Meta?.path ?? "/dashboards",
    meta: indexfLdCIEBIe1Meta || {},
    alias: indexfLdCIEBIe1Meta?.alias || [],
    redirect: indexfLdCIEBIe1Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: token6Uo5LGhhpFMeta?.name ?? "dashboards-token",
    path: token6Uo5LGhhpFMeta?.path ?? "/dashboards/token",
    children: [
  {
    name: _91token_93Ww5H51PjGvMeta?.name ?? "dashboards-token-token",
    path: _91token_93Ww5H51PjGvMeta?.path ?? ":token()",
    meta: _91token_93Ww5H51PjGvMeta || {},
    alias: _91token_93Ww5H51PjGvMeta?.alias || [],
    redirect: _91token_93Ww5H51PjGvMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/dashboards/token/[token].vue").then(m => m.default || m)
  }
],
    meta: token6Uo5LGhhpFMeta || {},
    alias: token6Uo5LGhhpFMeta?.alias || [],
    redirect: token6Uo5LGhhpFMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/dashboards/token.vue").then(m => m.default || m)
  },
  {
    name: demo4QlW7BWGr1Meta?.name ?? "demo",
    path: demo4QlW7BWGr1Meta?.path ?? "/demo",
    meta: demo4QlW7BWGr1Meta || {},
    alias: demo4QlW7BWGr1Meta?.alias || [],
    redirect: demo4QlW7BWGr1Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: index7MTclSwfL4Meta?.name ?? "events",
    path: index7MTclSwfL4Meta?.path ?? "/events",
    meta: index7MTclSwfL4Meta || {},
    alias: index7MTclSwfL4Meta?.alias || [],
    redirect: index7MTclSwfL4Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: impressumrgbOBORvYCMeta?.name ?? "impressum",
    path: impressumrgbOBORvYCMeta?.path ?? "/impressum",
    meta: impressumrgbOBORvYCMeta || {},
    alias: impressumrgbOBORvYCMeta?.alias || [],
    redirect: impressumrgbOBORvYCMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexHRyZqqYla1Meta?.name ?? "index",
    path: indexHRyZqqYla1Meta?.path ?? "/",
    meta: indexHRyZqqYla1Meta || {},
    alias: indexHRyZqqYla1Meta?.alias || [],
    redirect: indexHRyZqqYla1Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9375msu31TUPMeta?.name ?? "leaderboards-id",
    path: _91id_9375msu31TUPMeta?.path ?? "/leaderboards/:id()",
    meta: _91id_9375msu31TUPMeta || {},
    alias: _91id_9375msu31TUPMeta?.alias || [],
    redirect: _91id_9375msu31TUPMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/leaderboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexHHqhWx6ixJMeta?.name ?? "leaderboards",
    path: indexHHqhWx6ixJMeta?.path ?? "/leaderboards",
    meta: indexHHqhWx6ixJMeta || {},
    alias: indexHHqhWx6ixJMeta?.alias || [],
    redirect: indexHHqhWx6ixJMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/leaderboards/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nAva7e2z2ZMeta?.name ?? "organisation-deals-id",
    path: _91id_93nAva7e2z2ZMeta?.path ?? "/organisation/deals/:id()",
    meta: _91id_93nAva7e2z2ZMeta || {},
    alias: _91id_93nAva7e2z2ZMeta?.alias || [],
    redirect: _91id_93nAva7e2z2ZMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/deals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexbGOPnEOUTSMeta?.name ?? "organisation-deals",
    path: indexbGOPnEOUTSMeta?.path ?? "/organisation/deals",
    meta: indexbGOPnEOUTSMeta || {},
    alias: indexbGOPnEOUTSMeta?.alias || [],
    redirect: indexbGOPnEOUTSMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/deals/index.vue").then(m => m.default || m)
  },
  {
    name: HSLtoRGBYBPctswtnLMeta?.name ?? "organisation-devices-HSLtoRGB",
    path: HSLtoRGBYBPctswtnLMeta?.path ?? "/organisation/devices/HSLtoRGB",
    meta: HSLtoRGBYBPctswtnLMeta || {},
    alias: HSLtoRGBYBPctswtnLMeta?.alias || [],
    redirect: HSLtoRGBYBPctswtnLMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/HSLtoRGB.js").then(m => m.default || m)
  },
  {
    name: RGBtoHSLpDezQHWxOVMeta?.name ?? "organisation-devices-RGBtoHSL",
    path: RGBtoHSLpDezQHWxOVMeta?.path ?? "/organisation/devices/RGBtoHSL",
    meta: RGBtoHSLpDezQHWxOVMeta || {},
    alias: RGBtoHSLpDezQHWxOVMeta?.alias || [],
    redirect: RGBtoHSLpDezQHWxOVMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/RGBtoHSL.js").then(m => m.default || m)
  },
  {
    name: _91id_93ul2KYIAnKdMeta?.name ?? "organisation-devices-id",
    path: _91id_93ul2KYIAnKdMeta?.path ?? "/organisation/devices/:id()",
    meta: _91id_93ul2KYIAnKdMeta || {},
    alias: _91id_93ul2KYIAnKdMeta?.alias || [],
    redirect: _91id_93ul2KYIAnKdMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93C34V1Nryp2Meta?.name ?? "organisation-devices-devicegroup-id",
    path: _91id_93C34V1Nryp2Meta?.path ?? "/organisation/devices/devicegroup/:id()",
    meta: _91id_93C34V1Nryp2Meta || {},
    alias: _91id_93C34V1Nryp2Meta?.alias || [],
    redirect: _91id_93C34V1Nryp2Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/devicegroup/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkLPcHU4rQ5Meta?.name ?? "organisation-devices",
    path: indexkLPcHU4rQ5Meta?.path ?? "/organisation/devices",
    meta: indexkLPcHU4rQ5Meta || {},
    alias: indexkLPcHU4rQ5Meta?.alias || [],
    redirect: indexkLPcHU4rQ5Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YlkS3vQ5laMeta?.name ?? "organisation-eventTypes-id",
    path: _91id_93YlkS3vQ5laMeta?.path ?? "/organisation/eventTypes/:id()",
    meta: _91id_93YlkS3vQ5laMeta || {},
    alias: _91id_93YlkS3vQ5laMeta?.alias || [],
    redirect: _91id_93YlkS3vQ5laMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/eventTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDjIJhmU1FEMeta?.name ?? "organisation-eventTypes",
    path: indexDjIJhmU1FEMeta?.path ?? "/organisation/eventTypes",
    meta: indexDjIJhmU1FEMeta || {},
    alias: indexDjIJhmU1FEMeta?.alias || [],
    redirect: indexDjIJhmU1FEMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/eventTypes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sIfprInJolMeta?.name ?? "organisation-goals-id",
    path: _91id_93sIfprInJolMeta?.path ?? "/organisation/goals/:id()",
    meta: _91id_93sIfprInJolMeta || {},
    alias: _91id_93sIfprInJolMeta?.alias || [],
    redirect: _91id_93sIfprInJolMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/goals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexUoVxr0yd1gMeta?.name ?? "organisation-goals",
    path: indexUoVxr0yd1gMeta?.path ?? "/organisation/goals",
    meta: indexUoVxr0yd1gMeta || {},
    alias: indexUoVxr0yd1gMeta?.alias || [],
    redirect: indexUoVxr0yd1gMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/goals/index.vue").then(m => m.default || m)
  },
  {
    name: indexlm8DF85VHoMeta?.name ?? "organisation-settings",
    path: indexlm8DF85VHoMeta?.path ?? "/organisation/settings",
    meta: indexlm8DF85VHoMeta || {},
    alias: indexlm8DF85VHoMeta?.alias || [],
    redirect: indexlm8DF85VHoMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexSk37fzr5ZZMeta?.name ?? "organisation-subscriptions",
    path: indexSk37fzr5ZZMeta?.path ?? "/organisation/subscriptions",
    meta: indexSk37fzr5ZZMeta || {},
    alias: indexSk37fzr5ZZMeta?.alias || [],
    redirect: indexSk37fzr5ZZMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CNPbRzViwLMeta?.name ?? "organisation-users-id",
    path: _91id_93CNPbRzViwLMeta?.path ?? "/organisation/users/:id()",
    meta: _91id_93CNPbRzViwLMeta || {},
    alias: _91id_93CNPbRzViwLMeta?.alias || [],
    redirect: _91id_93CNPbRzViwLMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhw8h1kOtwlMeta?.name ?? "organisation-users",
    path: indexhw8h1kOtwlMeta?.path ?? "/organisation/users",
    meta: indexhw8h1kOtwlMeta || {},
    alias: indexhw8h1kOtwlMeta?.alias || [],
    redirect: indexhw8h1kOtwlMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/organisation/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CT9nzTUlybMeta?.name ?? "sounds-id",
    path: _91id_93CT9nzTUlybMeta?.path ?? "/sounds/:id()",
    meta: _91id_93CT9nzTUlybMeta || {},
    alias: _91id_93CT9nzTUlybMeta?.alias || [],
    redirect: _91id_93CT9nzTUlybMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/sounds/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDl7rNGo3EoMeta?.name ?? "sounds",
    path: indexDl7rNGo3EoMeta?.path ?? "/sounds",
    children: [
  {
    name: entriesmYDkYVWDedMeta?.name ?? "sounds-index-entries",
    path: entriesmYDkYVWDedMeta?.path ?? "entries",
    meta: entriesmYDkYVWDedMeta || {},
    alias: entriesmYDkYVWDedMeta?.alias || [],
    redirect: entriesmYDkYVWDedMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/sounds/index/entries.vue").then(m => m.default || m)
  },
  {
    name: playlistsvlrl99CxM4Meta?.name ?? "sounds-index-playlists",
    path: playlistsvlrl99CxM4Meta?.path ?? "playlists",
    meta: playlistsvlrl99CxM4Meta || {},
    alias: playlistsvlrl99CxM4Meta?.alias || [],
    redirect: playlistsvlrl99CxM4Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/sounds/index/playlists.vue").then(m => m.default || m)
  }
],
    meta: indexDl7rNGo3EoMeta || {},
    alias: indexDl7rNGo3EoMeta?.alias || [],
    redirect: indexDl7rNGo3EoMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/sounds/index.vue").then(m => m.default || m)
  },
  {
    name: index2ERSgf1stXMeta?.name ?? "system-devices",
    path: index2ERSgf1stXMeta?.path ?? "/system/devices",
    meta: index2ERSgf1stXMeta || {},
    alias: index2ERSgf1stXMeta?.alias || [],
    redirect: index2ERSgf1stXMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93O9qkTGf7W2Meta?.name ?? "system-firmware-id",
    path: _91id_93O9qkTGf7W2Meta?.path ?? "/system/firmware/:id()",
    meta: _91id_93O9qkTGf7W2Meta || {},
    alias: _91id_93O9qkTGf7W2Meta?.alias || [],
    redirect: _91id_93O9qkTGf7W2Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/firmware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexahvI4Llv3lMeta?.name ?? "system-firmware",
    path: indexahvI4Llv3lMeta?.path ?? "/system/firmware",
    meta: indexahvI4Llv3lMeta || {},
    alias: indexahvI4Llv3lMeta?.alias || [],
    redirect: indexahvI4Llv3lMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/firmware/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qR86WL4H6AMeta?.name ?? "system-hardware-id",
    path: _91id_93qR86WL4H6AMeta?.path ?? "/system/hardware/:id()",
    meta: _91id_93qR86WL4H6AMeta || {},
    alias: _91id_93qR86WL4H6AMeta?.alias || [],
    redirect: _91id_93qR86WL4H6AMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/hardware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNFTkoYWVWOMeta?.name ?? "system-hardware",
    path: indexNFTkoYWVWOMeta?.path ?? "/system/hardware",
    meta: indexNFTkoYWVWOMeta || {},
    alias: indexNFTkoYWVWOMeta?.alias || [],
    redirect: indexNFTkoYWVWOMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexYV4aDdY65FMeta?.name ?? "system-logs",
    path: indexYV4aDdY65FMeta?.path ?? "/system/logs",
    meta: indexYV4aDdY65FMeta || {},
    alias: indexYV4aDdY65FMeta?.alias || [],
    redirect: indexYV4aDdY65FMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/logs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cJurpqkc94Meta?.name ?? "system-notificationTemplates-id",
    path: _91id_93cJurpqkc94Meta?.path ?? "/system/notificationTemplates/:id()",
    meta: _91id_93cJurpqkc94Meta || {},
    alias: _91id_93cJurpqkc94Meta?.alias || [],
    redirect: _91id_93cJurpqkc94Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/notificationTemplates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexW8F1E8YhVtMeta?.name ?? "system-notificationTemplates",
    path: indexW8F1E8YhVtMeta?.path ?? "/system/notificationTemplates",
    meta: indexW8F1E8YhVtMeta || {},
    alias: indexW8F1E8YhVtMeta?.alias || [],
    redirect: indexW8F1E8YhVtMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/notificationTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_937NFTIDUcHCMeta?.name ?? "system-organisations-id",
    path: _91id_937NFTIDUcHCMeta?.path ?? "/system/organisations/:id()",
    meta: _91id_937NFTIDUcHCMeta || {},
    alias: _91id_937NFTIDUcHCMeta?.alias || [],
    redirect: _91id_937NFTIDUcHCMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZCKQ6obLSHMeta?.name ?? "system-organisations",
    path: indexZCKQ6obLSHMeta?.path ?? "/system/organisations",
    meta: indexZCKQ6obLSHMeta || {},
    alias: indexZCKQ6obLSHMeta?.alias || [],
    redirect: indexZCKQ6obLSHMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HscRRxYGSYMeta?.name ?? "system-plans-id",
    path: _91id_93HscRRxYGSYMeta?.path ?? "/system/plans/:id()",
    meta: _91id_93HscRRxYGSYMeta || {},
    alias: _91id_93HscRRxYGSYMeta?.alias || [],
    redirect: _91id_93HscRRxYGSYMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/plans/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYiAXQIkhsCMeta?.name ?? "system-plans",
    path: indexYiAXQIkhsCMeta?.path ?? "/system/plans",
    meta: indexYiAXQIkhsCMeta || {},
    alias: indexYiAXQIkhsCMeta?.alias || [],
    redirect: indexYiAXQIkhsCMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/plans/index.vue").then(m => m.default || m)
  },
  {
    name: releaselogFDjt4vSODHMeta?.name ?? "system-releaselog",
    path: releaselogFDjt4vSODHMeta?.path ?? "/system/releaselog",
    meta: releaselogFDjt4vSODHMeta || {},
    alias: releaselogFDjt4vSODHMeta?.alias || [],
    redirect: releaselogFDjt4vSODHMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/releaselog.vue").then(m => m.default || m)
  },
  {
    name: toolsMJirvS4ZRCMeta?.name ?? "system-tools",
    path: toolsMJirvS4ZRCMeta?.path ?? "/system/tools",
    meta: toolsMJirvS4ZRCMeta || {},
    alias: toolsMJirvS4ZRCMeta?.alias || [],
    redirect: toolsMJirvS4ZRCMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/tools.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Lxl2OKAStYMeta?.name ?? "system-users-id",
    path: _91id_93Lxl2OKAStYMeta?.path ?? "/system/users/:id()",
    meta: _91id_93Lxl2OKAStYMeta || {},
    alias: _91id_93Lxl2OKAStYMeta?.alias || [],
    redirect: _91id_93Lxl2OKAStYMeta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexAJ8MW9m8o8Meta?.name ?? "system-users",
    path: indexAJ8MW9m8o8Meta?.path ?? "/system/users",
    meta: indexAJ8MW9m8o8Meta || {},
    alias: indexAJ8MW9m8o8Meta?.alias || [],
    redirect: indexAJ8MW9m8o8Meta?.redirect || undefined,
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20240918095216/client/pages/system/users/index.vue").then(m => m.default || m)
  }
]